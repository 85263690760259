import logo from "./logo.svg";
import banner from "./images/king-logo.png";
import arrow from "./images/arrow.jpg";
import "./App.css";
import { useState, useEffect } from "react";
import moment from "moment";
import DayComponent from "./js/dayComponent";
import "moment-timezone";
import { useLocation, useNavigation } from "react-router";
import trackVisitor from "./utilities/tracker";
import { Link } from "react-router-dom";
import AdvertisementComponent from "./utilities/advertismentComponent";
import { Helmet } from "react-helmet";
const momenttz = require("moment-timezone");
function App() {
  const location = useLocation();
  const isAbout = location.pathname.includes("/about-us");
  const isDisclaimer = location.pathname.includes("/disclaimer");
  const isPrivacy = location.pathname.includes("/privacy-policy");
  const date = new Date();
  const currentDate = moment(date).format("YYYY-MM-DD");
  const prevDate = moment(date).subtract(1, "days").format("YYYY-MM-DD");
  const [data, setData] = useState([]); // State to store data fetched from backend
  const [datagame, setDataFor] = useState([]); // State to store processed data for display
  const [currentTime, setCurrentTime] = useState(moment().tz("Asia/Kolkata").format("HH:mm:ss"));
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment().tz("Asia/Kolkata").format("HH:mm:ss"));
    }, 1000); // Update every second
  
    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, []);
  

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  };

  useEffect(() => {
    trackVisitor();
  }, []);

  useEffect(() => {
    fetch("https://api.sattakingvip.co.in/getData", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        game_name: "",
        curr_date: currentDate,
        prev_date: prevDate,
        open_time: "market_sunday_time_open",
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        // Sort data based on open_time
        const sortedData = json.sort((a, b) => {
          const timeA = moment(a.open_time, "HH:mm");
          const timeB = moment(b.open_time, "HH:mm");
          return timeA.diff(timeB);
        });

        // Set sorted data into state
        setData(sortedData);
      })
      .catch((error) => console.error(error));
  }, [currentDate, prevDate]);
  useEffect(() => {
    if (data?.length > 0) {
      const currentMoment = moment(currentTime, "HH:mm");
  
      const processedData = data.map((item) => {
        const itemTime = moment(item.open_time, "HH:mm");
        const resultAvailable = item?.curr_date?.result ? true : false;
  
        return {
          gameName: item.game_name,
          result: resultAvailable ? item?.curr_date?.result : "wait",
          openTime: item.open_time,
          isAvailable: resultAvailable,
          itemTime: itemTime,
        };
      });
  
      const sortedProcessedData = processedData.sort((a, b) => 
        a.itemTime.diff(b.itemTime)
      );
  
      const availableResults = sortedProcessedData.filter((item) => item.isAvailable);
      const upcomingRecords = sortedProcessedData.filter((item) => !item.isAvailable);
  
      let recordsToDisplay = [];
  
      if (availableResults.length > 0) {
        recordsToDisplay = [...availableResults];
  
        const lastAvailableIndex = sortedProcessedData.indexOf(
          availableResults[availableResults.length - 1]
        );
        const nextRecord = sortedProcessedData[lastAvailableIndex + 1];
        if (nextRecord) {
          recordsToDisplay.push(nextRecord);
        }
      } else {
        recordsToDisplay = [...upcomingRecords.slice(0, 3)];
      }
  
      if (recordsToDisplay.length > 3) {
        recordsToDisplay = recordsToDisplay.slice(-3).reverse();
      }
  
      // Move "wait" items to the top
      recordsToDisplay.sort((a, b) => (a.result === "wait" ? -1 : 1));
  
      setDataFor(recordsToDisplay);
    }
  }, [data, currentTime]);
  return (
    <>
      <div className="menubar">
        {/* seo setup start */}
        <Helmet>
          <title>🎯Super Fast King🎯</title>
          <meta name="description" content="Super Fast King, SuperFastKing" />
          <meta
            name="Keywords"
            content="sking, s king s-king, sattakingreal, satta king real, sattaking real, Satta King, Satta King live result, Satta king online result, Satta king online, Satta king result today, Gali result, Desawar result, Faridabad result, Gaziyabad result, Satta matka king, Satta Bazar, Black satta king, Satta king 2017, satta king 2018, Gali Leak Number, Gali Single Jodi, Black Satta Result, Desawar Single Jodi, Satta king up, Satta king desawar, Satta king gali, Satta king 2019 chart, Satta baba king, Satta king chart, Gali live result, Disawar live result, Satta Number, Matka Number, Satta.com, Satta Game, Gali Number, Delhi Satta king,"
          />
          <link rel="canonical" href="https://superfastking.co/" />
        </Helmet>
        {/* seo setup end */}
        <div className="col-12">
          <div className="row">
            <div className="col-md-3 col-sm-12 menulink" value="MORNING START">
              <Link to="/MORNING_STAR">MORNING START</Link>
            </div>
            <div className="col-md-3 col-sm-12 menulink" value="SILVER CITY">
              <Link to="/SILVER_CITY">SILVER CITY</Link>
            </div>
            <div className="col-md-3 col-sm-12 menulink" value="LONDON BAZAR">
              <Link to="/LONDON_BAZAR">LONDON BAZAR</Link>
            </div>
            <div className="col-md-3 col-sm-12 menulink" value="DEV DARSHAN">
              <Link to="/DEV_DARSHAN">DEV DARSHAN</Link>
            </div>
          </div>
        </div>
        <div className="col-12 logomenu" style={{
          borderRadius: "10px",
          backgroundColor: "#e5ce8e"
        }}>
          <div className="row">
            {/* <div className="col-6 d-flex">
              <a href="/">
                <img className="col-6" src={banner} alt="bannerimage" />
              </a>
            </div> */}
            {/* data */}

            <div className="result text-center">
              <h2 className="text-center fw-bold">👑SUPER FAST KING👑</h2>
              
              {/* <h6 className="text-center fw-bold">{new Date().toLocaleString('en-US', options)}</h6> */}
            </div>
            <div className="result text-center mt-2">
            <p className="text-center" style={{fontSize:'26px',fontWeight:'bolder',color:'yellowgreen'}}>🕗{currentTime}🕗</p> {/* Display running current time */}
            <p className="text-center"style={{fontSize:'26px',fontWeight:'bolder',color:'yellowgreen'}}>{currentDate}</p> {/* Display current date */}
              {datagame?.map((todayData, indexApp) => (
                <div key={indexApp} className="game">
                  
                  <h3 className="mb-0">{todayData?.gameName}</h3>
                  <h5 className=" lh-1 blinking-text">
                    {todayData?.result || ""}
                  </h5>
                </div>
              ))}
            </div>

            {/* <div className="text-center mt-4" style={{
              borderRadius: "10px",
              backgroundColor: "red",
              padding: "16px 2px"
            }}>
              <h3 className="text-center fw-bold">Market Name</h3>
              <p className="text-center fw-bold">(Open time)</p>
            </div> */}

{data?.map((item, index) => (
  <div
    key={index}
    className="text-center mt-4"
    style={{
      borderRadius: "10px",
      backgroundColor: "#dc3545",
      padding: "16px 2px"
    }}
  >
    <h2 className="text-center fw-bold text-white">{item?.game_name}</h2>
    <h4 className="text-center fw-bold text-white">{`(${item?.open_time})`}</h4>
    <h4 className="text-center fw-bold text-white">{item?.curr_date?.result || "Undeclared "}
    <span>
      <img src={arrow}/>
    </span>
    </h4>
   
  </div>
))}



          </div>
        </div>
        {!(isAbout || isDisclaimer || isPrivacy) && (
          <>
            <marquee>
              Super Fast King provide daily live satta result and chart of Satta
              King 2024, Satta King 2023, satta king fast, up satta king, satta
              king result, satta king chart, सट्टा किंग, Satta king Desawar ,
              Satta king Gali, Satta king Ghaziabad, Satta king Faridabad,Satta
              king Delhi Darbarand Satta King Taj, Old Taj Result, Delhi Bazar
              Result, Desawar Result, Shri Ganesh Result and More.
            </marquee>

            <div className="col-12 dayResult text-center pt-2">
              <h3>🚨SUPER FAST KING LIVE RESULT🚨</h3>
              <h6>♠️SATTA KING♠️ | ♠️SATTA RESULT♠️ | ♠️SATTA RECORDS♠️</h6>
            </div>
         
            <AdvertisementComponent type="odd" />
            {/* <DayComponent dayGameData={data} /> */}
          </>
        )}
      </div>
    </>
  );
}

export default App;
